import '@algolia/autocomplete-theme-classic/dist/theme.css';
import './App.css';

import {
  Configure,
  HierarchicalMenu,
  Hits,
  InstantSearch,
  Pagination,
  Panel,
  Snippet,
  connectSearchBox,
} from 'react-instantsearch-dom';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Autocomplete } from './Autocomplete';
import algoliasearch from 'algoliasearch/lite';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import qs from 'qs';

export const INSTANT_SEARCH_INDEX_NAME = 'dev_products';

const searchClient = algoliasearch(
  'UXDXGCP896',
  '9479d9caaee82ee3d6646405ef957612'
);

function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  // Remove configure search state from query parameters
  const { configure, ...rest } = searchState;
  return `${location.pathname}${createURL(rest)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

const VirtualSearchBox = connectSearchBox(() => null);

export function App() {
  const [searchState, setSearchState] = useState(() =>
    urlToSearchState(window.location)
  );
  const timerRef = useRef(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      window.history.pushState(
        searchState,
        null,
        searchStateToUrl({ location: window.location }, searchState)
      );
    }, 400);
  }, [searchState]);

  const currentCategory = useMemo(
    () =>
      searchState?.hierarchicalMenu?.[
        INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES[0]
      ] || '',
    [searchState]
  );

  const onSubmit = useCallback(({ state }) => {
    setSearchState((searchState) => ({
      ...searchState,
      query: state.query,
    }));
  }, []);
  const onReset = useCallback(() => {
    setSearchState((searchState) => ({
      ...searchState,
      query: '',
    }));
  }, []);

  const plugins = useMemo(() => {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setSearchState((searchState) => ({
              ...searchState,
              query: item.label,
            }));
          },
        };
      },
    });

    return [
      recentSearchesPlugin
    ];
  }, [currentCategory]);

  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName={INSTANT_SEARCH_INDEX_NAME}
        searchState={searchState}
        onSearchStateChange={setSearchState}
        createURL={createURL}
      >
        <header className="header">
          <div className="header-wrapper wrapper">
            <nav className="header-nav">
              <a href="/">Home</a>
            </nav>
            {/* A virtual search box is required for InstantSearch to understand the `query` search state property */}
            <VirtualSearchBox />
            <Autocomplete
              placeholder="Search products"
              detachedMediaQuery="none"
              initialState={{
                query: searchState.query,
              }}
              openOnFocus={true}
              onSubmit={onSubmit}
              onReset={onReset}
              plugins={plugins}
              insights
            />
          </div>
        </header>

        <Configure
          attributesToSnippet={['name:7', 'description:15']}
          snippetEllipsisText="…"
        />
        <div className="container wrapper">
          <div>
            <Hits hitComponent={Hit} />
            <Pagination />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

function Hit({ hit }) {
  return (
    <article className="hit">
      <div className="hit-image">
        <img src={hit.images?.[0]?.src} alt={hit.title} />
      </div>
      <div>
        <h1>
          {hit?.title}
        </h1>
        <div>
        </div>
      </div>
    </article>
  );
}
